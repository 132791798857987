import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { fetchCommonListApi } from "../action/common";
import check_tick from "../assets/images/check_tick.svg";
import transArrow from "../assets/images/trans_arrow.svg";
import { Loader } from "../pages/loader";

const BarChart = () => {
  const [loading, setLoading] = useState(true);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [selectValue, setSelectValue] = useState("weekly");
  const selectRef = useRef(null);
  const handleSelect = () => {
    setToggleSelect(!toggleSelect);
  };

  const handleChange = (event) => {
    setSelectValue(event.value);
  };

  const getSelectedValue = (value) => {
    const selectedElement = selectDataValue.find((ele) => ele.name === value);
    return selectedElement ? selectedElement.value : "";
  };
  const selectDataValue = [
    { name: "Weekly", value: "weekly" },
    { name: "Monthly", value: "monthly" },
    { name: "Yearly", value: "yearly" },
  ];

  const [chartOption, setChartOption] = useState({
    series: [
      {
        name: "Earnings",
        data: [],
      },
    ],
    chart: {
      height: 250,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '15%',
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "0";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: [],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "%";
        },
      },
    },
  });

  const getDashboardData = async () => {
    setLoading(true);
    const res = await fetchCommonListApi(
      `/api/request/earning?filter=${selectValue}`
    );
    if (res.status) {
      const apiResponse = res.data;
      const seriesData = apiResponse.map((item) => item.total);
      const categories = apiResponse.map((item) => item._id);
      chartOption.series[0].data = seriesData;
      chartOption.xaxis.categories = categories;

      setChartOption(chartOption);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDashboardData();
  }, [selectValue]);

  // Detect clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setToggleSelect(false); // Remove active class if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  return loading ? (
    <p>
      <Loader />
    </p>
  ) : (
    <div className="app">
      <div className="payout_data-head-top">
        <div className="payout_data-left">
          <h6 class="graph_lable">Income Chart</h6>
        </div>
        <div className="payout_data-right">
          <div className="payout_data-head-bottom_left">
            <div
               ref={selectRef}
              className={`transaction_id ${toggleSelect ? "active" : ""}`}
              onClick={handleSelect}
            >
              {selectValue}
              <img src={transArrow} alt="" className="transArrow" />
              <div className="transDrop">
                {selectDataValue.map((ele) => (
                  <div
                    key={ele.value}
                    className={`transDrop-item  ${
                      ele.value === getSelectedValue(selectValue)
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      console.log("DJIF", ele);
                      handleChange(ele);
                    }}
                  >
                    {ele.name}{" "}
                    <span>
                      {" "}
                      <img
                        className="checkTichImg"
                        src={check_tick}
                        alt="tick"
                      />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartOption}
            series={chartOption.series}
            type="bar"
            width="1100"
          />
        </div>
      </div>
    </div>
  );
};

export default BarChart;
