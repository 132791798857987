import React, { useState, useCallback, useEffect } from "react";
import "../../styles/milestone.css";
import trash from "../../assets/images/trash-03.svg";
import { crateMilestone, fetchMilestones } from "../../action/request";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";

const Milestones = ({ onMilestonesChange, serviceId }) => {
  const [rows, setRows] = useState([]);
  const [deletedMilestoneIds, setDeletedMilestoneIds] = useState([]); // New state variable
  const [isAdding, setIsAdding] = useState(false);
  const [newMilestone, setNewMilestone] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const addMilestoneFormik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      serviceId: serviceId,
    },
    onSubmit: async (values) => {
      const response = await crateMilestone(values);
      if (response.status) {
        addMilestoneFormik.setFieldValue("name", "");
        addMilestoneFormik.setFieldValue("desc", "");
        getMilestones();
      } else {
        toast.error(response.message);
      }
    },
  });

  const handleDragStart = useCallback((e, index) => {
    e.dataTransfer.setData("text/plain", index);
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleInputChange = (e, index) => {
    const updatedRows = [...rows];
    updatedRows[index].name = e.target.value;
    setRows(updatedRows);
  };

  const handleNewMilestoneChange = (e) => {
    setNewMilestone(e.target.value);
  };

  const handleCancelAddMilestone = () => {
    setNewMilestone("");
    setIsAdding(false);
  };

  const transformMilestones = (milestones) => {
    return milestones.map((milestone) => ({
      checklistId: milestone._id,
      status: 0,
    }));
  };

  const updateRows = (newRows) => {
    setRows(newRows);
    const transformedMilestones = transformMilestones(newRows);
    onMilestonesChange(transformedMilestones);
  };

  const handleDrop = useCallback(
    (e, index) => {
      e.preventDefault();
      const fromIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);
      if (fromIndex !== index) {
        const updatedRows = [...rows];
        const [movedItem] = updatedRows.splice(fromIndex, 1);
        updatedRows.splice(index, 0, movedItem);
        updateRows(updatedRows);
      }
    },
    [rows]
  );

  const handleAddMilestone = () => {
    if (newMilestone.length > 1) {
      const newId = (rows.length + 1).toString();
      updateRows([...rows, { id: newId, name: newMilestone }]);
      setNewMilestone("");
      setIsAdding(false);
    }
  };

  const handleDeleteMilestone = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    const deletedId = rows[index]._id; // Get the _id of the deleted milestone
    setDeletedMilestoneIds([...deletedMilestoneIds, deletedId]); // Add to deletedMilestoneIds
    updateRows(updatedRows);
  };

  const getMilestones = async () => {
    setLoading(true);
    try {
      const response = await fetchMilestones(serviceId);
      if (response.status) {
        const filteredMilestones = response.data.checklists.filter(
          (milestone) => !deletedMilestoneIds.includes(milestone._id)
        );
        updateRows(filteredMilestones);
      } else {
        setError("Failed to fetch milestones");
      }
    } catch (err) {
      setError("An error occurred while fetching milestones");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMilestones();
  }, [serviceId]);

  useEffect(() => {
    addMilestoneFormik.setFieldValue("serviceId", serviceId);
  }, [serviceId]);

  return (
    <div className="scroll_y">
      <table id="table" className="milestone_tb">
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={row._id}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDrop={(e) => handleDrop(e, index)}
              onDragOver={handleDragOver}
              data-id={row._id}
            >
              <td className="text-center">
                <svg
                  style={{ height: "30px" }}
                  fill="#000000"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon flat-line"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <polyline
                      points="10 9 12 7 14 9"
                      style={{
                        fill: "none",
                        stroke: "#000000",
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeWidth: "2",
                      }}
                    ></polyline>
                    <polyline
                      points="14 15 12 17 10 15"
                      style={{
                        fill: "none",
                        stroke: "#000000",
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeWidth: "2",
                      }}
                    ></polyline>
                    <path
                      d="M6,3H18M6,21H18M12,7V17"
                      style={{
                        fill: "none",
                        stroke: "#000000",
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeWidth: "2",
                      }}
                    ></path>
                  </g>
                </svg>
              </td>
              <td className="table_amount text-center">
                {row.name ? (
                  row.name
                ) : (
                  <input
                    type="text"
                    value={row.name}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                )}
              </td>
              <td className="table_amount text-center">{row.desc ?? "-"}</td>
              <td className="table_amount" align="center">
                <img
                  src={trash}
                  alt=""
                  className="pointer del"
                  onClick={() => handleDeleteMilestone(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="row mb-3">
        <div className="col-lg-4">
          <div className="input-box">
            <div className="input_field">
              <input
                type="text"
                className="input"
                name="name"
                value={addMilestoneFormik.values.name}
                onChange={addMilestoneFormik.handleChange}
                placeholder="Add New Milestone"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-box">
            <div className="input_field">
              <input
                type="text"
                className="input"
                name="desc"
                value={addMilestoneFormik.values.desc}
                onChange={addMilestoneFormik.handleChange}
                placeholder="Enter description"
                maxLength={60}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <button
            className="mt-3 btn rounded-pill btn-success"
            type="button"
            disabled={!addMilestoneFormik.values.name}
            onClick={() => {
              if (addMilestoneFormik.values.name) {
                addMilestoneFormik.handleSubmit();
              }
            }}
          >
            Add New Milestone
          </button>
        </div>
      </div>
    </div>
  );
};

export default Milestones;
