import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import ProgressChart from "../../charts/chart";
import BarChart from "../../charts/barChart";
import { fetchCommonListApi } from "../../action/common";
import { Loader } from "../loader";

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSelect, setToggleSelect] = useState(false);

  const [weeklyChart, setWeeklyChart] = useState([
    {
      name: "Total Requests",
      data: [],
      count: 0,
      card: "one",
    },
    {
      name: "New Requests",
      data: [],
      count: 0,
      card: "two",
    },
    {
      name: "Approved Requests",
      data: [],
      count: 0,
      card: "three",
    },
    {
      name: "Completed Requests",
      data: [],
      count: 0,
      card: "four",
    },
  ]);

  const getDashboardData = async () => {
    setIsLoading(true);
    const res = await fetchCommonListApi("/api/request/count");
    if (res.status) {
      //total requests
      weeklyChart[0].data = res.data[0]?.graph.map(
        (value) => value.totalRequests
      );
      weeklyChart[0].count = res.data[0]?.total;

      //new requests
      weeklyChart[1].data = res.data[1]?.graph.map(
        (value) => value.totalRequests
      );
      weeklyChart[1].count = res.data[1]?.new;

      //approved request
      weeklyChart[2].data = res.data[2]?.graph.map(
        (value) => value.totalRequests
      );
      weeklyChart[2].count = res.data[2]?.approved;

      //completed request
      weeklyChart[3].data = res.data[3]?.graph.map(
        (value) => value.totalRequests
      );
      weeklyChart[3].count = res.data[3]?.completed;

      setWeeklyChart(weeklyChart);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <div className="payout_data-content mb-5">
        <div className="dashboard_head">
          <div className="dashboard_head-left">
            <div className="dashboard_title">Dashboard</div>
          </div>
        </div>
        <div className="dashboard_cards">
          <div className="row mb-5">
            {isLoading ? (
              <p>
                <Loader />
              </p>
            ) : (
              weeklyChart.map((value) => {
                return (
                  <div className="col-lg-3">
                    {/* total request graph */}
                    <div className={`card ${value.card}`}>
                      <h6 class="card-title h6">{value.name}</h6>
                      <div className="card-content">
                        <span className="count">{value.count}</span>
                        <div className="chart-container">
                          <ProgressChart seriesData={value.data} />
                          <div className="chart-label">
                            <span className="label-text">
                              Last 7 Days Progress
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <div className="payout_data-content">
        <div className="payout_data-head">
          <BarChart />
        </div>
      </div>
    </>
  );
};
