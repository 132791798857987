import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { ToasterProvider } from "./common/toaster/toaster";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fortawesome/fontawesome-free/css/all.css";
const theme = createTheme({
  typography: {
    fontFamily: "poppins",
  },
});
// const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToasterProvider>
      <App />
    </ToasterProvider>
  </Provider>
);
