import { useState } from "react";
import { ReusableDialog } from "../common/dialog";

export const Logout = ({ open, onClose, onLogout }) => {
  const dialogStyles = {
    width: "30%",
    height: "auto",
    maxWidth: "none",
  };
  return (
    <ReusableDialog
      open={open}
      onClose={onClose}
      dialogStyles={dialogStyles}
      body={
        <div className="modal-body">
          <div>
            <h5 style={{ textAlign: "center" }}>
              Are you sure you want to logout?
            </h5>
          </div>
          <div className="mt-4">
            <div className="benyf_btn">
              <button
                className="beny_left-btn"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </button>
              <button
                className="beny_right-btn1"
                onClick={() => {
                  onLogout();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};
