import React, { useState, useEffect } from "react";
import backBtn from "../../assets/images/chevron-left.svg";
import { useFormik } from "formik";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../loader";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchRequestedContractorList,
  putAssignToContractor,
} from "../../action/request";
import Pagination from "../../components/customPaginantion/paginationView";

export const RequestedContractors = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [contractorId, setContractorId] = useState("");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUserList = async () => {
    setIsLoading(true);
    const response = await fetchRequestedContractorList(id, 1, 10);
    if (response.status) {
      setUsers(response.data);
      setTotalRecords(response.data.length);
      setIsLoading(false);
    } else {
    }
  };

  const assignProject = async () => {
    const payload = {
      contractorId: contractorId,
    };
    const response = await putAssignToContractor(id, payload);
    if (response.status) {
      toast.success(response.message);
      navigate("/requests/approved");
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const handlePaginationChange = (event) => {
    setPaginationValue(event);
  };

  return (
    <>
      {/* <div className="payout_data-content tablePage_content_header"> */}
      {/* <div className="row">
          <div className="col-lg-3">
            <div className="input-box">
              <div className="input-label">Search</div>
              <div className="input_field">
                <input
                  type="text"
                  className="input"
                  placeholder="Type any keyword"
                  id="beneficiary"
                  name="name"
                />
                <div className="build_search">
                  <img src={searchIcon} alt="Search Icon" />
                </div>
              </div>
            </div>
          </div>
        
        </div>
        <div className="submit_btn_grp">
          <button className="btn_submit">Reset</button>
          <button className="btn_submit active">Search</button>
        </div> */}
      {/* </div> */}
      <div className="payout_data-head py-2">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl1">
              <img
                className="i_button"
                src={backBtn}
                alt="back"
                onClick={() => {
                  navigate("/requests/approved");
                }}
              />
              Contractors
            </div>
          </div>
        </div>
      </div>
      <div className="customtable_box">
        <table className="customtable">
          <thead>
            <tr>
              <th>
                <div>Contractor Name</div>
              </th>
              <th>
                <div>Mobile</div>
              </th>
              <th>
                <div>Email</div>
              </th>
              <th>
                <div>Business Name</div>
              </th>
              <th>
                <div>Address</div>
              </th>
              <th>
                <div>Services</div>
              </th>
              <th className="text-center">
                <div>Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {users.length > 0 ? (
                  users.map((item) => {
                    return (
                      <tr key={item?._id}>
                        <td className="noWrap">{item.user}</td>
                        <td className="address_td">{item?.mobile}</td>
                        <td>{item?.email}</td>
                        <td>{item?.business?.name}</td>
                        <td nowrap className=" noWrap">
                          {item?.address?.address}
                        </td>
                        <td>
                          {item?.business?.services.map((value) => {
                            return <div>{value?.name}</div>;
                          })}
                        </td>
                        <td className="text-center">
                          <button
                            type="button"
                            class="btn_accept btn_submit"
                            onClick={() => {
                              setContractorId(item.contractorId);
                              setIsOpenModal(true);
                            }}
                          >
                            Assign
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />

        {/* Add milestone model */}
        <Modal
          show={isOpenModal}
          onHide={() => {
            setIsOpenModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assign Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure want to assign task to this contractor?
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setIsOpenModal(false);
              }}
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={assignProject}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
