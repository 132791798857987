import React, { useState, useEffect } from "react";
import trash from "../../assets/images/trash-03.svg";
import backBtn from "../../assets/images/chevron-left.svg";
import { useFormik } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Milestones from "./milestone";
import { createProject, fetchRequestDetails } from "../../action/request";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import { uploadMediaImage } from "../../action/user";
import { handleKeyDown } from "../../helper/helper";
import FileUpload from "../../components/uploadFile/fileUpload";

export const ProjectAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const imagesTypes = ["JPG", "PNG", "JPEG"];
  const docTypes = ["PDF", "DOC", "TXT", "DOCX"];

  const [userRequest, setUserRequest] = useState({});
  const [measurements, setMeasurements] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [newMeasure, setNewMeasure] = useState("");
  const [paymentTitle, setPaymentTitle] = useState("");
  const [newAmount, setAmount] = useState(0);
  const [payments, setPayments] = useState([]);
  const [fileDataArray, setFileDataArray] = useState([]);
  const [docArray, setDocArray] = useState([]);
  const [isTrending, setIsTrending] = useState(false);
  const [serviceId, setServiceId] = useState("");

  const handleAddField = () => {
    if (newTitle && newMeasure) {
      setMeasurements((prev) => [
        ...prev,
        { title: newTitle, measure: newMeasure },
      ]);
      setNewTitle("");
      setNewMeasure("");
    }
  };

  const handleAddPayments = () => {
    if (paymentTitle && newAmount) {
      setPayments((prev) => [
        ...prev,
        { title: paymentTitle, amount: newAmount },
      ]);
      setPaymentTitle("");
      setAmount("");
    }
  };

  const handleFieldChange = (index, key, value) => {
    const updatedMeasurements = measurements.map((measurement, i) =>
      i === index ? { ...measurement, [key]: value } : measurement
    );
    setMeasurements(updatedMeasurements);
  };

  const handleDeleteField = (index) => {
    setMeasurements((prev) => prev.filter((_, i) => i !== index));
  };

  const addProjectFormik = useFormik({
    initialValues: {
      measurements: [
        {
          title: "measurement",
          measure: "",
        },
      ],
      images: [
        {
          title: "",
          url: "",
        },
      ],
      documents: [
        {
          title: "",
          url: "",
        },
      ],
      // estimationAmount: {
      //   amount: "",
      // },
      materialAmount: {
        amount: "",
      },
      platformAmount: {
        amount: "",
      },
      labourAmount: {
        amount: "",
      },
      vatAmount: {
        amount: "",
        percentage: "",
      },
      // payments: [],
      milestones: [],
      duration: "",
      type: "",
      requestId: id,
      isTrending: false,
    },
    onSubmit: async (values) => {
      // values.measurements = measurements;
      values.payments = payments;
      values.milestones = milestones;
      values.isTrending = isTrending;
      if (fileDataArray.length > 0) {
        await Promise.all(
          fileDataArray.map(async (item, index) => {
            const formData = new FormData();
            formData.append("file", item.file);
            const res = await uploadMediaImage(formData, "service");
            if (res.status) {
              if (!addProjectFormik.values.images[index]) {
                addProjectFormik.values.images[index] = { title: "", url: "" };
              }
              addProjectFormik.values.images[index].url = res.data[0];
              addProjectFormik.values.images[index].title = item.title;
            }
          })
        );
      }

      if (docArray.length > 0) {
        await Promise.all(
          docArray.map(async (item, index) => {
            const formData = new FormData();
            formData.append("file", item.file);
            const res = await uploadMediaImage(formData, "service");
            if (res.status) {
              if (!addProjectFormik.values.documents[index]) {
                addProjectFormik.values.documents[index] = {
                  title: "",
                  url: "",
                };
              }
              addProjectFormik.values.documents[index].url = res.data[0];
              addProjectFormik.values.documents[index].title = item.title;
            }
          })
        );
      }
      const isEmpty = Object.values(values.images[0]).every(
        (value) => value !== ""
      );
      if (!isEmpty) {
        values.images = [];
      }

      const isEmptyDocuments = Object.values(values.documents[0]).every(
        (value) => value !== ""
      );
      if (!isEmptyDocuments) {
        values.documents = [];
      }

      //checking if measurement is empty
      const isEmptyMeasurement = Object.values(values.measurements[0]).every(
        (value) => value !== ""
      );
      if (!isEmptyMeasurement) {
        values.measurements = [];
      }
      console.log("data  ", values);

      const response = await createProject(values);
      if (response.status) {
        toast.success("Project created Successfully");
        navigate("/requests/approved");
      } else {
        toast.error(response.message);
      }
    },
  });

  // const uploadImage = async (type, file, index, title) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   const res = await uploadMediaImage(formData, "service");
  //   if (res.status) {
  //     if (type == "image") {
  //       if (!addProjectFormik.values.images[index]) {
  //         addProjectFormik.values.images[index] = { title: "", url: "" };
  //       }
  //       addProjectFormik.values.images[index].url = res.data[0];
  //       addProjectFormik.values.images[index].title = title;
  //     } else {
  //       if (!addProjectFormik.values.documents[index]) {
  //         addProjectFormik.values.documents[index] = { title: "", url: "" };
  //       }
  //       addProjectFormik.values.documents[index].url = res.data[0];
  //       addProjectFormik.values.documents[index].title = title;
  //     }
  //   }
  // };

  const getRequestDetails = async () => {
    const response = await fetchRequestDetails(id);
    if (response.status) {
      setUserRequest(response.data);
      setServiceId(response.data?.serviceDetails?._id);
    }
  };

  const handleAddPayment = () => {
    // const newSerial = payments.length + 1;
    setPayments([...payments, { title: "", amount: 0 }]);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedPayments = payments.map((payment, i) =>
      i === index ? { ...payment, [name]: value } : payment
    );
    setPayments(updatedPayments);
  };

  const handleDeletePayment = (index) => {
    const updatedPayments = payments.filter((_, i) => i !== index);
    setPayments(updatedPayments);
  };

  const handleAddMoreOption = () => {
    addProjectFormik.setFieldValue("images", [
      ...addProjectFormik.values.images,
      "",
    ]);
  };

  const handelDeleteOption = (index) => {
    addProjectFormik.setFieldValue(
      "images",
      addProjectFormik.values.images.filter((value, ind) => {
        return ind !== index;
      })
    );
  };

  useEffect(() => {
    getRequestDetails();
  }, []);

  const [milestones, setMilestones] = useState([]);

  const handleMilestonesChange = (updatedMilestones) => {
    setMilestones(updatedMilestones);
  };

  useEffect(() => {
    const labourAmount =
      parseFloat(addProjectFormik.values.labourAmount.amount) || 0;
    const platformAmount =
      parseFloat(addProjectFormik.values.platformAmount.amount) || 0;
    const vatPercentage =
      parseFloat(addProjectFormik.values.vatAmount.percentage) || 0;

    // Calculate VAT Amount
    const vatAmount = (labourAmount + platformAmount) * (vatPercentage / 100);

    // Update the formik value for vatAmount.amount
    addProjectFormik.setFieldValue(
      "vatAmount.amount",
      Number(vatAmount.toFixed(2))
    );
  }, [
    addProjectFormik.values.labourAmount.amount,
    addProjectFormik.values.platformAmount.amount,
    addProjectFormik.values.vatAmount.percentage,
  ]);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="section-heading top_heading">
              <img
                className="i_button"
                src={backBtn}
                alt="back"
                onClick={() => {
                  navigate("/requests/new");
                }}
              />
              Create Project
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box"></div>
      <div className="payout_data-content mb-5 fist_Sec">
        {/* <table>
          <tr>
            <td>Request ID:</td>
            <td><strong>{data?.requestId}</strong></td>
            <td>Consumer Name:</td>
            <td><strong>{data?.user}</strong></td>
          </tr>
          <tr>
            <td>Service:</td>
            <td><strong>{data?.service}</strong></td>
            <td>Address:</td>
            <td><strong>{data?.address}</strong></td>
          </tr>
          <tr>
            <td>Date:</td>
            <td><strong>{moment(data?.scheduleDate).format("DD-MM-YYYY hh:mm A")}</strong></td>
            <td></td>
            <td></td>
          </tr>
        </table> */}
        <div className="row mb-2">
          <div className="col-lg-4">
            <div className="data">
              Request ID: <span>{userRequest?.requestId}</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <div className="data">
                Consumer Name: <span>{userRequest?.userDetails?.name}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="data">
              Service: <span>{userRequest?.serviceDetails?.name}</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="data">
              Address: <span>{userRequest?.addressDetails?.address}</span>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="data">
              Scheduled Date & Time:{" "}
              <span>
                {moment(userRequest?.schedule).format("DD MMM YYYY hh:mm a")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box"></div>

      {/* <div className="payout_table-box"> */}
      <form
        action=""
        // className="payment_form"
        onSubmit={addProjectFormik.handleSubmit}
      >
        <div className="row mb-2">
          <div className="section-sub-heading mb-3">Milestones</div>

          <div className="col-lg-12">
            <Milestones
              onMilestonesChange={handleMilestonesChange}
              serviceId={serviceId}
            />
          </div>
        </div>

        <div className="section-heading mt-5">Project Details</div>

        <div className="payout_data-content mb-5 second_sec">
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label mb-2">
                  Project Type
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter project type"
                    id="beneficiary"
                    name="type"
                    onChange={(e) => {
                      addProjectFormik.setFieldValue("type", e.target.value);
                    }}
                    value={addProjectFormik.values.type ?? ""}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label mb-2">
                  Work Duration
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter work duration"
                    id="beneficiary"
                    name="duration"
                    onChange={(e) => {
                      addProjectFormik.setFieldValue(
                        "duration",
                        e.target.value
                      );
                    }}
                    value={addProjectFormik.values.duration ?? ""}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="input-box">
              <div className="input-label"></div>
              <div className="input-label">Is Trending?</div>
              <div className="checkform_box">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    onChange={() => {
                      setIsTrending(true);
                    }}
                    required
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                    onChange={() => {
                      setIsTrending(false);
                    }}
                    required
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* <table className="customtable">
            <thead>
              <tr>
                <th>#</th>
                <th>Measurement Title</th>
                <th>Measurement Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {measurements.length ? (
                measurements.map((value, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{value.title}</td>
                      <td>{value.measure}</td>
                      <td>
                        <img
                          src={trash}
                          alt=""
                          style={{ marginLeft: "10px", marginTop: "15px" }}
                          className="pointer del"
                          onClick={() => {
                            handleDeleteField(index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    Please Add Measurement
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row one_row">
            <div className="col-lg-4">
              <div className="input-box">
                <div className="input_field">
                  <input
                    type="text"
                    placeholder="Title"
                    value={newTitle}
                    className="input"
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="input-box">
                <div className="input_field">
                  <input
                    type="text"
                    placeholder="Enter a value"
                    value={newMeasure}
                    className="input"
                    onChange={(e) => setNewMeasure(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <button
                className="mt-3 btn rounded-pill btn-success"
                type="button"
                onClick={handleAddField}
                disabled={!newTitle}
              >
                Add New Measurement
              </button>
            </div>
          </div> */}

          <div className="row one_row">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label mb-2">Measurement</div>
                <div className="input_field">
                  <textarea
                    placeholder="Title"
                    value={addProjectFormik.values.measurements[0].measure}
                    className="input"
                    onChange={(e) =>
                      addProjectFormik.setFieldValue(
                        `measurements[0].measure`,
                        e.target.value
                      )
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            {/* {addProjectFormik.values.images.length > 0 ? (
              addProjectFormik.values.images.map((value, index) => {
                return (
                  <div className="col-lg-3">
                    <div className="d-flex">
                      <div className="input-box w-100">
                        <div className="input_field">
                          <input
                            type="text"
                            placeholder="Enter image title"
                            value={addProjectFormik.values.images[index].title}
                            className="input"
                            onChange={(e) =>
                              addProjectFormik.setFieldValue(
                                `images[${index}].title`,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      {addProjectFormik.values.images.length > 1 ? (
                        <img
                          src={trash}
                          alt=""
                          style={{ marginLeft: "10px", marginTop: "15px" }}
                          className="pointer del"
                          onClick={() => {
                            handelDeleteOption(index);
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className="option-card mt-3"
                      style={{ display: "flex" }}
                    >
                      <ThumbnailPicker
                        width="60%"
                        height="250px"
                        onFileSelect={(file) => {
                          const _URL = window.URL || window.webkitURL;

                          const img = new Image();
                          uploadImage(file, index);

                          img.src = _URL.createObjectURL(file);
                        }}
                        image={addProjectFormik.values.images[index].url}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )} */}
            <div className="row mb-3">
              <div className="col-lg-6">
                <div className="input-label mb-2">Upload Images</div>

                <FileUpload
                  fileDataArray={fileDataArray}
                  setFileDataArray={setFileDataArray}
                  fileTypes={imagesTypes}
                />
              </div>
              {/* </div>
            <div className="row one_row"> */}
              <div className="col-lg-6">
                <div className="input-label mb-2">Upload Documents</div>

                <FileUpload
                  fileDataArray={docArray}
                  setFileDataArray={setDocArray}
                  fileTypes={docTypes}
                />
              </div>
            </div>
          </div>
          {/* <div className="input-box">
            <div>
              <button
                type="button"
                isLight
                className="mt-4 btn rounded-pill btn-success"
                onClick={handleAddMoreOption}
              >
                Add More Images
              </button>
            </div>
          </div> */}
        </div>
        <div className="section-heading">Budget Details</div>
        <div className="payout_data-content third_sec">
          {/* <div className="row mb-3"> */}
          {/* </div> */}
          <div className="row mb-4">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Material Amount
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter material amount"
                    id="beneficiary"
                    min="1"
                    max="1000000"
                    name="materialAmount.amount"
                    onChange={addProjectFormik.handleChange}
                    value={addProjectFormik.values.materialAmount.amount}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Labour Amount
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter labour amount"
                    id="beneficiary"
                    min="1"
                    max="1000000"
                    name="labourAmount.amount"
                    onChange={addProjectFormik.handleChange}
                    value={addProjectFormik.values.labourAmount.amount}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Platform Amount
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter platform amount"
                    id="beneficiary"
                    min="1"
                    max="1000000"
                    name="platformAmount.amount"
                    onChange={addProjectFormik.handleChange}
                    value={addProjectFormik.values.platformAmount.amount}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Vat Percentage
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter vat"
                    id="beneficiary"
                    min="1"
                    max="100"
                    name="vatAmount.percentage"
                    onChange={addProjectFormik.handleChange}
                    value={addProjectFormik.values.vatAmount.percentage}
                    required
                    // onKeyDown={(e) => {
                    //   calculateVatAmount(e.target.value);
                    // }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Vat Amount
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter vat amount"
                    id="beneficiary"
                    name="vatAmount.amount"
                    onChange={addProjectFormik.handleChange}
                    value={addProjectFormik.values.vatAmount.amount}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <table className="customtable">
            <thead>
              <tr>
                <th>#</th>
                <th>Installment Title</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {payments.length ? (
                payments.map((value, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{value.title}</td>
                      <td>{value.amount}</td>
                      <td>
                        <img
                          src={trash}
                          alt=""
                          style={{ marginLeft: "10px", marginTop: "15px" }}
                          className="pointer del"
                          onClick={() => {
                            handleDeletePayment(index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    Please Add Installment
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row one_row">
            <div className="col-lg-4">
              <div className="input-box">
                <div className="input_field">
                  <input
                    type="text"
                    name="title"
                    className="input"
                    value={paymentTitle}
                    onChange={(e) => setPaymentTitle(e.target.value)}
                    placeholder="Title"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="input-box">
                <div className="input_field">
                  <input
                    type="text"
                    name="amount"
                    className="input"
                    value={newAmount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Amount"
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div> 
            <div className="col-lg-4">
              <button
                className="mt-3 btn rounded-pill btn-success"
                type="button"
                onClick={handleAddPayments}
                disabled={!paymentTitle || !newAmount}
              >
                Add New Installment
              </button>
            </div>
          </div>*/}
        </div>
        <div>
          <div className="d-flex justify-content-end gap-2 mt-4">
            <button
              className="btn btn-warning rounded-pill"
              type="button"
              onClick={() => {
                navigate("/requests/new");
              }}
            >
              Cancel
            </button>
            <button className="btn btn-success rounded-pill" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
      {/* </div> */}
    </div>
  );
};
