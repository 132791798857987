import { requestWithToken } from "../helper/helper";

const fetchCommonListApi = async (url) => {
  try {
    const response = await requestWithToken("GET", url);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

export { fetchCommonListApi };
