// FileUpload.js
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import trashIcon from "../../assets/images/trash-03.svg";
import infoIcon from "../../assets/images/eye.svg";

const FileUpload = ({ fileDataArray, setFileDataArray, fileTypes }) => {
  const handleFileChange = (files) => {
    const updatedFiles = Array.from(files).map((file) => ({
      file: file,
      url: URL.createObjectURL(file),
      title: "", // Placeholder for title, can be updated later
    }));
    setFileDataArray([...fileDataArray, ...updatedFiles]);
  };

  const handleInputChange = (e, index) => {
    const newFiles = [...fileDataArray];
    newFiles[index].title = e.target.value;
    setFileDataArray(newFiles);
  };

  const handleDelete = (index) => {
    const updatedFiles = fileDataArray.filter((_, i) => i !== index);
    setFileDataArray(updatedFiles);
  };

  return (
    <div>
      <FileUploader className="uploader"
        handleChange={handleFileChange}
        name="file"
        types={fileTypes}
        multiple={true}
        onDrop={handleFileChange}
      />
      {fileDataArray.length > 0 && (
      <div className="customtable_box mt-2 scroll-v">
          <table className="customtable">
          <thead>
            <tr>
              <th>File Name</th>
              <th>File URL</th>
              <th>Title</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {fileDataArray.map(({ file, url, title }, index) => (
              <tr key={index}>
                <td>{file.name}</td>
                <td>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <img src={infoIcon} className="fitimg" />
                  </a>
                </td>
                {/* <td>
                  {file.type.startsWith("image/") && (
                    <img src={url} alt={file.name} width="100" />
                  )}
                </td> */}
                <td>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder="Enter title"
                    required
                  />
                </td>
                <td>
                  <img className="i_button" onClick={() => handleDelete(index)} src={trashIcon} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      )}
    </div>
  );
};

export default FileUpload;
