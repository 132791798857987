import React, { useState, useEffect } from "react";
import filterImg from "../../assets/images/Up_Down.svg";
import filter2 from "../../assets/images/filter2.svg";
import infoImage from "../../assets/images/info-circle.svg";
import { useFormik } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import Offcanvas from "react-bootstrap/Offcanvas";
import { toast } from "react-hot-toast";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import {
  fetchRequestDetails,
  fetchRequestsByStatus,
  putRequestApprove,
} from "../../action/request";
import CommonFilter from "../../components/CommonFilter/commonFilter";
import Pagination from "../../components/customPaginantion/paginationView";

export const ApprovedReqests = () => {
  const navigate = useNavigate();
  //   const [date, setDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [userRequest, setUserRequest] = useState([]);
  const [id, setId] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [details, setDetails] = useState({});
  const [isTrending, setIsTrending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [status, setStatus] = useState(9);

  const handleClose = () => {
    setIsOpenModal(false);
    editProjectFormik.resetForm();
  };
  const handleShow = () => setIsOpenModal(true);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (value) => {
    console.log(value);

    const sts =
      value == "Pending Approval"
        ? 1
        : value == "Awaiting Contractor"
        ? 5
        : value == "Requests"
        ? 6
        : value == "All"
        ? 9
        : 7;
    setStatus(sts);
    setSelectedStatus(value);
    setIsDropdownOpen(false);
  };

  const fetchList = async (search = "", category = "") => {
    setIsLoading(true);
    const response = await fetchRequestsByStatus(
      currentPage,
      pageSize,
      status,
      category,
      search,
      sort,
      order
    );
    if (response.status) {
      setUserRequest(response?.data?.requests);
      setTotalRecords(response?.data?.count);
      setIsLoading(false);
    } else {
    }
  };

  const editProjectFormik = useFormik({
    initialValues: {
      materialAmount: {
        percentage: "",
      },
      estimationAmount: {
        amount: 0,
      },
      platformAmount: {
        percentage: "",
      },
      labourAmount: {
        percentage: "",
      },
      isTrending: false,
    },
    onSubmit: async (values, { resetForm }) => {
      values.isTrending = isTrending;
      const response = await putRequestApprove(id, values);
      if (response.status) {
        fetchList();
        toast.success(response.message);
        handleClose();
        resetForm();
      } else {
        toast.error(response.message);
      }
    },
  });

  const getRequestDetails = async () => {
    const response = await fetchRequestDetails(id);
    if (response.status) {
      setDetails(response.data);
      // console.log("data  ", details);
      editProjectFormik.setFieldValue(
        "estimationAmount.amount",
        response.data.projectDetails?.estimationAmount?.amount
      );
    }
  };

  const handleRedirect = (requestId) => {
    navigate(`/requests/approved/contractor/${requestId}`);
  };

  const handleDetailsPage = (reqId) => {
    navigate(`/requests/approved/details/${reqId}`);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, pageSize, sort, order, status]);

  const statusColorMapping = {
    // 4: "failed",
    5: "failed",
    7: "processing",
    // 3: "pending",
    3: "rejected",
    6: "reverse",
    1: "success",
  };
  const getStatusClassName = (status) => {
    return statusColorMapping[status] || "";
  };

  const handleClickOutside = (event) => {
    if (
      isDropdownOpen &&
      !event.target.closest(".dropdown-menu1") &&
      !event.target.closest(".cursor")
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <>
      <CommonFilter
        showOptions={{
          Search: {
            type: "text",
            field_name: "search",
          },
          Category: {
            type: "select",
            field_name: "category",
            api_url: "/api/service",
            success_key_name: "services",
          },
          // Region: {
          //   type: "select",
          //   field_name: "region",
          //   api_url: "/api/region",
          //   success_key_name: "regions",
          // },
        }}
        onSubmit={(values) => {
          fetchList(values.search, values.category, values.region);
        }}
      />

      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl1">Approved Requests</div>
          </div>
        </div>
      </div>
      <div className="customtable_box">
        <table className="customtable">
          <thead>
            <tr>
              <th>
                <div className="filterBox">
                  Request ID
                  {/* <img src={filterImg} alt="filter" className="filterImg" /> */}
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Consumer Name
                  <img
                    src={filterImg}
                    alt="filter"
                    className="filterImg i_button"
                    onClick={() => {
                      setSort("name");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Service
                  <img
                    src={filterImg}
                    alt="filter"
                    className="filterImg i_button"
                    onClick={() => {
                      setSort("service");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Address
                  {/* <img src={filterImg} alt="filter" className="filterImg" /> */}
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Scheduled Date
                  <img
                    src={filterImg}
                    alt="filter"
                    className="filterImg i_button"
                    onClick={() => {
                      setSort("schedule");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Start Date
                  {/* <img src={filterImg} alt="filter" className="filterImg" /> */}
                </div>
              </th>
              <th className="text-center cursor">
                <div className="text-center" onClick={toggleDropdown}>
                  Status
                  <img src={filter2} alt="filter" className="filterImg" />
                </div>

                {/* {isDropdownOpen && (
                  <div className="dropdown-menu1">
                    <ul>
                      <li>All</li>
                      <li>Pending Approval</li>
                      <li>Awaiting Contractor</li>
                      <li>Requests</li>
                      <li>In Progress</li>
                    </ul>
                  </div>
                )} */}
                {isDropdownOpen && (
                  <div className="dropdown-menu1">
                    <ul>
                      {[
                        "All",
                        "Pending Approval",
                        "Awaiting Contractor",
                        "Requests",
                        "In Progress",
                      ].map((value) => (
                        <li
                          key={value}
                          onClick={() => handleSelect(value)}
                          className={selectedStatus === value ? "selected" : ""}
                        >
                          {value}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </th>
              <th>
                <div className="text-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {" "}
            {isLoading ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {userRequest.length > 0 ? (
                  userRequest.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="noWrap">{item.requestId ?? "-"}</td>
                        <td>{item?.userDetails?.name}</td>
                        <td>{item?.serviceDetails?.name}</td>
                        <td className="ellipsis">
                          {item?.addressDetails?.address}
                        </td>
                        <td>
                          {moment(item?.schedule).format("DD MMM YYYY hh:mm a")}
                        </td>
                        <td>
                          {item?.projectDetails?.startDate
                            ? moment(item?.projectDetails?.startDate).format(
                                "DD MMM YYYY"
                              )
                            : "-"}
                        </td>
                        <td className="text-center status_col">
                          <div
                            className={`d-flex gap-2 ${getStatusClassName(
                              item.status
                            )}`}
                          >
                            {item?.status == 1
                              ? "Pending Approval"
                              : item?.status == 3
                              ? "Broadcast"
                              : item.status == 5
                              ? "Awaiting Contractor"
                              : item.status == 6
                              ? `Requests`
                              : "In Progress"}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center gap-2">
                            {item?.status == 3 ? (
                              <button
                                type="button"
                                class="btn_accept btn_submit"
                                onClick={() => {
                                  setId(item?._id);
                                  setIsOpenModal(true);
                                }}
                              >
                                Broadcast
                              </button>
                            ) : item.status == 6 ? (
                              <button
                                type="button"
                                class="btn_accept btn_submit"
                                onClick={() => {
                                  handleRedirect(item?._id);
                                }}
                                disabled={
                                  item?.requestContractorCount > 0
                                    ? false
                                    : true
                                }
                              >
                                <span>
                                  {item?.requestContractorCount ?? 0} Requests
                                </span>
                              </button>
                            ) : item.status == 7 ? (
                              <>
                                {/* <img
                                  src={editBtn}
                                  alt=""
                                  onClick={() => {
                                    setId(item?._id);
                                    setIsOpenPaymentModel(true);
                                  }}
                                /> */}
                                <img
                                  className="i_button"
                                  src={infoImage}
                                  alt=""
                                  onClick={() => {
                                    handleDetailsPage(item?._id);
                                  }}
                                />
                              </>
                            ) : (
                              <img
                                className="i_button"
                                src={infoImage}
                                alt=""
                                onClick={() => {
                                  handleDetailsPage(item?._id);
                                }}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPageSize(page);
            setCurrentPage(1);
          }}
        />

        {/* offcanvas to add contractor amount percentage */}
        <Offcanvas show={isOpenModal} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add Contractor Amount</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form action="" onSubmit={editProjectFormik.handleSubmit}>
              <div className="row">
                <div className="input-label h2">
                  Total Budget:{" "}
                  <span>
                    {details?.projectDetails?.estimationAmount?.amount}
                  </span>
                </div>

                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">
                      Material Percentage
                      <span>*</span>
                    </div>
                    <div className="inline_field">
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter material amount"
                          id="beneficiary"
                          name="materialAmount"
                          onChange={(e) => {
                            editProjectFormik.setFieldValue(
                              "materialAmount.percentage",
                              Number(e.target.value)
                            );
                          }}
                          value={
                            editProjectFormik.values.materialAmount.percentage
                          }
                          required
                          max={100}
                          min={0}
                        />
                      </div>
                      <div className="input-label">
                        Total Material Amount:{" "}
                        <span>
                          {details?.projectDetails?.estimationAmount?.amount
                            ? (details?.projectDetails?.estimationAmount
                                ?.amount *
                                editProjectFormik.values.materialAmount
                                  .percentage) /
                              100
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">
                      Platform Percentage
                      <span>*</span>
                    </div>
                    <div className="inline_field">
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter platform amount"
                          id="beneficiary"
                          name="platformAmount"
                          onChange={(e) => {
                            editProjectFormik.setFieldValue(
                              "platformAmount.percentage",
                              Number(e.target.value)
                            );
                          }}
                          value={
                            editProjectFormik.values.platformAmount.percentage
                          }
                          required
                          max={100}
                          min={0}
                        />
                      </div>
                      <div className="input-label">
                        Total Platform Amount:{" "}
                        <span>
                          {details?.projectDetails?.estimationAmount?.amount
                            ? (details?.projectDetails?.estimationAmount
                                ?.amount *
                                editProjectFormik.values.platformAmount
                                  .percentage) /
                              100
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">
                      Labour Percentage
                      <span>*</span>
                    </div>
                    <div className="inline_field">
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter labour amount"
                          id="beneficiary"
                          name="labourAmount"
                          onChange={(e) => {
                            editProjectFormik.setFieldValue(
                              "labourAmount.percentage",
                              Number(e.target.value)
                            );
                          }}
                          value={
                            editProjectFormik.values.labourAmount.percentage
                          }
                          required
                          max={100}
                          min={0}
                        />
                      </div>
                      <div className="input-label">
                        Total Labour Amount:{" "}
                        <span>
                          {details?.projectDetails?.estimationAmount?.amount
                            ? (details?.projectDetails?.estimationAmount
                                ?.amount *
                                editProjectFormik.values.labourAmount
                                  .percentage) /
                              100
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="input-box">
                    <div className="input-label"></div>
                    <div className="input-label">Is Trending?</div>
                    <div className="checkform_box">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          onChange={() => {
                            setIsTrending(true);
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                          onChange={() => {
                            setIsTrending(false);
                          }}
                          checked
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-start gap-2 mt-4">
                  <button
                    className="btn btn-success rounded-pill"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};
