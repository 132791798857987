import { SideMenuItems } from "./sideNavItems";
import { menu } from "../data/sidenav";
import logo from "../assets/images/logo.svg";
import logoIcon from "../assets/images/logo-icon.svg";
import dummyProfileImage from "../assets/images/logo-icon.svg";
import logoutIcon from "../assets/images/log-out.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useEffect } from "react";
import chevronDownIcon from "../assets/images/chevron-down.svg";
import { Logout } from "./logout";

export function SideNav() {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [toggle, setToggle] = useState(false);
  const toggleButton = () => {
    setToggle(!toggle);
  };
  const [open, setOpen] = useState(false);

  const handleClosePopUp = () => {
    setOpen(false);
  };

  useEffect(() => {
    setData(JSON.parse(localStorage.getItem("user")));
  }, [localStorage.getItem("auth-token")]);

  const handleLogOut = () => {
    setOpen(true);
  };

  const logout = () => {
    localStorage.clear();
    toast.success("Logout Successfully");
    navigate("/login");
    setOpen(false);
  };

  return (
    <>
      <div className={`sideMenu ${toggle ? "collapse" : ""}`}>
        <div className="sidemenu_top">
          <div className="sideMenu_head">
            <div className="sidelogo">
              <img src={logo} alt="" className="sidelogoImg" />
            </div>
            <div className="sidelogoIcon">
              <img src={logoIcon} alt="" className="sidelogoImgIcon" />
            </div>
          </div>

          {menu.map((item, index) => (
            <SideMenuItems key={index} item={item} toggle={toggle} />
          ))}
        </div>
        <div className="sideMenu-bottom">
          <div className="side_bottom-card-box">
            <div className="side_bottom-card">
              <div className="bottom_crd-image">
                <img src={dummyProfileImage} alt="" />
              </div>
              <div className="bottom_crd-text">
                <div className="bottom_crd-title ">
                  <div className="d-flex">
                    {data?.name?.length > 13
                      ? data?.name.substring(0, 13) + "..."
                      : data?.name}
                  </div>
                  <div className="bottom_crd-desc">{data?.email}</div>
                </div>
              </div>
              <div className="logout_btn">
                <img
                  src={logoutIcon}
                  alt=""
                  className="logout_img"
                  onClick={handleLogOut}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse_btn ${toggle ? "active" : ""}`}
        onClick={toggleButton}
      >
        <img src={chevronDownIcon} alt="" className="collapse_sideMenu" />
      </div>

      <Logout open={open} onClose={handleClosePopUp} onLogout={logout} />
    </>
  );
}
