import { requestWithToken } from "../helper/helper";

const fetchContactUs = async (page, limit, search, sort, order) => {
  try {
    const queryParams = [];

    if (page) queryParams.push(`page=${page}`);
    if (limit) queryParams.push(`limit=${limit}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/api/contactus${queryString}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchAskUs = async (page, limit, search, sort, order) => {
  try {
    const queryParams = [];

    if (page) queryParams.push(`page=${page}`);
    if (limit) queryParams.push(`limit=${limit}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken("GET", `/api/askus${queryString}`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchServiceQueries = async (page, limit, search, sort, order) => {
  try {
    const queryParams = [];

    if (page) queryParams.push(`page=${page}`);
    if (limit) queryParams.push(`limit=${limit}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/api/serviceQuery${queryString}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

export { fetchContactUs, fetchAskUs, fetchServiceQueries };
