import { requestWithToken } from "../helper/helper";

const fetchService = async () => {
  try {
    const response = await requestWithToken("GET", `/api/service`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchRegions = async () => {
  try {
    const response = await requestWithToken("GET", `/api/region`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchTowns = async () => {
  try {
    const response = await requestWithToken("GET", `/api/town`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchVillages = async () => {
  try {
    const response = await requestWithToken("GET", `/api/village`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

export { fetchService, fetchRegions, fetchTowns, fetchVillages };
