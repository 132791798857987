import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

export const ReusableDialog = ({
  open,
  onClose,
  title,
  body,
  buttons,
  dialogStyles,
  padding,
}) => {
  const handleModalClick = (event) => {
    event.stopPropagation();
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
  return (
    <Dialog
      className="dialogModalClass"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onclose(event, reason);
        }
      }}
      PaperProps={{
        style: {
          ...dialogStyles,
        },
      }}
      onClick={handleBackdropClick}
    >
      <DialogTitle>
        {title}{" "}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding }} onClick={handleModalClick}>
        {body}
      </DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
};
