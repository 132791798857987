import { requestWithToken, requestWithoutToken } from "../helper/helper";
import {
  AUTH_USER,
  AUTH_USER_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../action/types";

// Authenticate User
export const authUser = () => async (dispatch) => {
  try {
    const response = await requestWithoutToken("GET", "/user/login");

    dispatch({
      type: AUTH_USER,
      payload: response.data.data,
    });

    return response.data;
  } catch (err) {
    // dispatch(error(err));
    dispatch({ type: AUTH_USER_FAIL });

    return err.response.data;
  }
};

// Login User
export const login = async (payload) => {
  try {
    const response = await requestWithoutToken(
      "POST",
      "/api/user/login",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
    // dispatch(error(err));
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
