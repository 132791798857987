import React, { useState, useEffect } from "react";
import infoImage from "../../assets/images/info-circle.svg";
import filterImg from "../../assets/images/Up_Down.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import Pagination from "../../components/customPaginantion/paginationView";
import { Loader } from "../loader";
import { fetchUsers } from "../../action/user";
import { useNavigate } from "react-router-dom";
import CommonFilter from "../../components/CommonFilter/commonFilter";

export const Contractors = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");

  const getUserList = async (search = "") => {
    setIsLoading(true);
    const response = await fetchUsers(
      currentPage,
      paginationValue,
      "CONTRACTOR",
      search,
      sort,
      order
    );
    if (response.status) {
      setUsers(response.data.users);
      setTotalRecords(response?.data?.count);
      setIsLoading(false);
    } else {
    }
  };

  useEffect(() => {
    getUserList();
  }, [currentPage, paginationValue, sort, order]);

  const handlePaginationChange = (event) => {
    setCurrentPage(event);
  };

  const handleRedirect = () => {
    navigate("/contractors/add");
  };

  const handleSearch = () => {
    getUserList();
  };

  return (
    <>
      <CommonFilter
        showOptions={{
          Search: {
            type: "text",
            field_name: "search",
          },
        }}
        onSubmit={(values) => {
          getUserList(values.search);
        }}
      />
      <div className="payout_data-head py-2">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl1">Contractors</div>
          </div>
          <div className="payout_data-right">
            <button className="btn_submit" onClick={handleRedirect}>
              + Add New
            </button>
          </div>
        </div>
      </div>
      <div className="customtable_box">
        <table className="customtable">
          <thead>
            <tr>
              <th>
                <div className="filterBox">
                  Name
                  <img
                    src={filterImg}
                    alt="filter"
                    className="i_button filterImg"
                    onClick={() => {
                      setSort("name");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div>Mobile</div>
              </th>
              <th>
                <div>Email</div>
              </th>
              <th>
                <div>Address</div>
              </th>
              <th>
                <div>Business Name</div>
              </th>
              <th className="text-center">
                <div>Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {users.length > 0 ? (
                  users.map((item) => {
                    return (
                      <tr key={item?._id}>
                        <td className="noWrap">{item.name}</td>
                        <td className="address_td">
                          {item?.mobile ? item?.mobile : "-"}
                        </td>
                        <td>{item?.email}</td>
                        <td nowrap className=" noWrap">
                          {item?.address?.location ?? "-"}
                        </td>
                        <td>{item?.business?.name ?? "-"}</td>
                        <td className="text-center">
                          <img
                            className="i_button"
                            src={infoImage}
                            alt=""
                            onClick={() => {
                              navigate(`/contractors/details/${item?._id}`);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="9" className="text-center">
                        No contractor found
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />
      </div>
    </>
  );
};
