import React, { useEffect, useState } from "react";
import Select from "react-select";
import searchIcon from "../../assets/images/build_search.svg";
import { fetchCommonListApi } from "../../action/common";
import { useFormik } from "formik";

const CommonFilter = ({ showOptions, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [extraApiData, setExtraApiData] = useState([]);

  const searchAndFilterFormik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const fetchData = (index, url, keyName) => {
    fetchCommonListApi(url).then((response) => {
      if (response.status) {
        extraApiData[index] = response?.data[keyName]?.map((item) => {
          return { value: item._id, label: item.name };
        });
        setExtraApiData(extraApiData);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    setExtraApiData([]);

    Object.keys(showOptions).map((value, index) => {
      searchAndFilterFormik.setFieldValue(
        `${showOptions[value].field_name}`,
        ""
      );

      if (showOptions[value]?.api_url) {
        fetchData(
          index,
          showOptions[value]?.api_url,
          showOptions[value]?.success_key_name
        );
      }
    });

    setLoading(false);
  }, []);

  const isFormValid = Object.values(searchAndFilterFormik.values).some(
    (value) => value !== "" && value !== null
  );

  return !loading ? (
    <form onSubmit={searchAndFilterFormik.handleSubmit}>
      <div className="payout_data-content tablePage_content_header">
        <div className="row">
          {Object.keys(showOptions)?.map((form, index) => {
            if (showOptions[form].type === "text") {
              return (
                <div className="col-lg-3">
                  <div className="input-box">
                    <div className="input-label">{form}</div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Type any keyword"
                        id="beneficiary"
                        name={`${showOptions[form].field_name}`}
                        value={
                          searchAndFilterFormik.values[
                            `${showOptions[form].field_name}`
                          ]
                        }
                        onChange={searchAndFilterFormik.handleChange}
                      />
                      <div className="build_search">
                        <img src={searchIcon} alt="Search Icon" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            if (showOptions[form].type === "select") {
              return (
                <div className="col-lg-3">
                  <div className="input-box">
                    <div className="input-label">{form}</div>
                    <Select
                      name={`${showOptions[form].field_name}`}
                      placeholder={`${form} select`}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={
                        extraApiData[index] != undefined
                          ? extraApiData[index]
                          : []
                      }
                      value={
                        extraApiData[index] != undefined
                          ? extraApiData[index].filter(
                              (data) =>
                                data.value ===
                                searchAndFilterFormik.values[
                                  `${showOptions[form].field_name}`
                                ]
                            )
                          : []
                      }
                      onChange={(e) => {
                        searchAndFilterFormik.setFieldValue(
                          `${showOptions[form].field_name}`,
                          e.value
                        );
                      }}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className="submit_btn_grp">
          <button
            className="btn_submit"
            type="button"
            onClick={() => {
              searchAndFilterFormik.handleReset();
              Object.keys(showOptions).map((value, index) => {
                searchAndFilterFormik.setFieldValue(
                  `${showOptions[value].field_name}`,
                  ""
                );
              });
              searchAndFilterFormik.handleSubmit();
            }}
            disabled={!isFormValid}
          >
            Reset
          </button>
          <button
            className={`btn_submit ${isFormValid ? "active" : ""}`}
            type="submit"
            disabled={!isFormValid}
          >
            Search
          </button>
        </div>
      </div>
    </form>
  ) : (
    <p>Loading...</p>
  );
};

export default CommonFilter;
