import React, { useEffect } from "react";

import { Navigate, useLocation, redirect } from "react-router-dom";

export const ProtectedRoute = (props) => {
  const { children } = props;
  let location = useLocation();
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};
