import React, { useState, useEffect } from "react";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Loader } from "../loader";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../components/customPaginantion/paginationView";
import { fetchContactUs } from "../../action/queries";
import filterImg from "../../assets/images/Up_Down.svg";
import CommonFilter from "../../components/CommonFilter/commonFilter";

export const ContactUs = () => {
  const { id } = useParams();
  //   const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [queries, setQueries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");

  const getContactUsList = async (search = "") => {
    setIsLoading(true);
    const response = await fetchContactUs(
      currentPage,
      paginationValue,
      search,
      sort,
      order
    );
    if (response.status) {
      setQueries(response.data.contactus);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getContactUsList();
  }, [currentPage, paginationValue, sort, order]);

  return (
    <>
      <div className="payout_data-head py-2">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl1">Contact Us</div>
          </div>
        </div>
      </div>
      <CommonFilter
        showOptions={{
          Search: {
            type: "text",
            field_name: "search",
          },
        }}
        onSubmit={(values) => {
          getContactUsList(values.search);
        }}
      />
      <div className="customtable_box">
        <table className="customtable">
          <thead>
            <tr>
              <th>
                <div>
                  {" "}
                  Name
                  <img
                    src={filterImg}
                    alt="filter"
                    className="filterImg i_button"
                    onClick={() => {
                      setSort("name");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div>Mobile</div>
              </th>
              <th>
                <div>Email</div>
              </th>
              <th>
                <div>Role</div>
              </th>
              <th>
                <div>Message</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {queries.length > 0 ? (
                  queries.map((item) => {
                    return (
                      <tr key={item?._id}>
                        <td className="noWrap">{item?.name}</td>
                        <td className="address_td">{item?.mobile}</td>
                        <td>{item?.email}</td>
                        <td>{item?.role}</td>
                        <td>{item?.message}</td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />
      </div>
    </>
  );
};
