import React, { useState, useEffect } from "react";
import filterImg from "../../assets/images/Up_Down.svg";
import infoIcon from "../../assets/images/info-circle.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/customPaginantion/paginationView";
import { getFeedbacks, updateFeedback } from "../../action/user";
import { useFormik } from "formik";

export const Feedbacks = () => {
  const navigate = useNavigate();
  //   const [date, setDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [feedbcakList, setFeedbackList] = useState([]);
  const [isLoad, setIsLoading] = useState(false);
  const [status, setStatus] = useState();
  const [error, setError] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");

  const fetchFeedbacks = async (search = "") => {
    setIsLoading(true);
    const status = 0;
    const response = await getFeedbacks(
      currentPage,
      paginationValue,
      status,
      search,
      sort,
      order
    );
    if (response.status) {
      setFeedbackList(response?.data?.feedbacks);
      setTotalRecords(response?.data?.count);
      setIsLoading(false);
    }
  };

  const feedbackFormik = useFormik({
    initialValues: {
      feedbackId: "",
      status: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      const response = await updateFeedback(values);

      if (response.status) {
        toast.success(response.message);
        fetchFeedbacks();
        feedbackFormik.resetForm();
        setIsOpenModal(false);
        setIsOpenRejectModal(false);
      } else {
        toast.error(response.message);
      }
    },
  });

  useEffect(() => {
    fetchFeedbacks();
  }, [currentPage, paginationValue, status, sort, order]);

  return (
    <div class="payout_inner_content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl1">Feedback</div>
          </div>
        </div>
      </div>
      <div className="customtable_box">
        <table className="customtable">
          <thead>
            <tr>
              <th>
                <div className="filterBox">
                  Consumer Name
                  <img
                    src={filterImg}
                    alt="filter"
                    className="i_button filterImg"
                    onClick={() => {
                      setSort("name");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Service
                  <img
                    src={filterImg}
                    alt="filter"
                    className="i_button filterImg"
                    onClick={() => {
                      setSort("service");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">Description</div>
              </th>
              <th>
                <div className="filterBox">Rating</div>
              </th>
              <th>
                <div className="text-center">Status</div>
              </th>
              <th>
                <div className="text-center">Action</div>
              </th>
            </tr>
          </thead>

          <tbody>
            {isLoad ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {feedbcakList.length > 0 ? (
                  feedbcakList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{item?.user?.name}</td>
                        <td>{item?.service?.name}</td>
                        <td>{item?.desc}</td>
                        <td>{item?.rating}</td>
                        <td>
                          <div className="d-flex justify-content-center gap-2">
                            {!item?.status || item?.status === 0 ? (
                              <>
                                <button
                                  type="button"
                                  class="btn_accept btn_submit"
                                  onClick={() => {
                                    feedbackFormik.setFieldValue(
                                      "feedbackId",
                                      item?._id
                                    );
                                    feedbackFormik.setFieldValue(
                                      "status",
                                      Number(1)
                                    );
                                    setIsOpenModal(true);
                                  }}
                                >
                                  Accept
                                </button>
                                <button
                                  type="button"
                                  class="btn btn_reject btn_submit"
                                  onClick={() => {
                                    feedbackFormik.setFieldValue(
                                      "feedbackId",
                                      item?._id
                                    );
                                    feedbackFormik.setFieldValue(
                                      "status",
                                      Number(2)
                                    );
                                    setIsOpenRejectModal(true);
                                  }}
                                >
                                  Reject
                                </button>
                              </>
                            ) : item?.status === 1 ? (
                              "Approved"
                            ) : (
                              "Rejected"
                            )}
                          </div>
                        </td>
                        <td className="text-center">
                          <img className="i_button"
                            src={infoIcon}
                            onClick={() => {
                              navigate(
                                `/requests/approved/details/${item?.requestId}`
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />

        {/* Add milestone model */}
        <Modal
          show={isOpenModal}
          onHide={() => {
            setIsOpenModal(false);
            feedbackFormik.resetForm();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to Approve this feedback?</Modal.Body>
          <Modal.Footer>
            <form onSubmit={feedbackFormik.handleSubmit} className="feedback_ys_no">
              <button
                type="button"
                class="btn btn-secondary no-btn"
                data-bs-dismiss="modal"
                onClick={() => {
                  setIsOpenModal(false);
                }}
              >
                No
              </button>
              <button type="submit" class="btn btn-primary yes-btn">
                Yes
              </button>
            </form>
          </Modal.Footer>
        </Modal>

        {/* CANCEL Request model */}
        <Modal
          show={isOpenRejectModal}
          onHide={() => {
            setIsOpenRejectModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to reject this feedback?</Modal.Body>
          <Modal.Footer>
            <form onSubmit={feedbackFormik.handleSubmit}>
              <button
                type="button"
                class="btn btn-secondary no-btn"
                data-bs-dismiss="modal"
                onClick={() => {
                  setIsOpenModal(false);
                }}
              >
                No
              </button>
              <button type="submit" class="btn btn-primary yes-btn">
                Yes
              </button>
            </form>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
