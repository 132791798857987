import fstacLogoIcon from "../assets/images/fstag-logo.svg";
import homeIcon from "../assets/images/home.svg";
import sideRightIcon from "../assets/images/side_right.svg";
import codeIcon from "../assets/images/code.svg";
import payoutIcon from "../assets/images/payout.svg";
import dashboardIcon from "../assets/images/dashboard.svg";
import fileIcon from "../assets/images/file-code.svg";
import reportIcon from "../assets/images/report.svg";
import needHelpIcon from "../assets/images/need_help.svg";
import settingIcon from "../assets/images/settings.svg";
import logoutIcon from "../assets/images/log-out.svg";
import ArrowDownIcon from "../assets/images/arrowDownIcon.svg";
import onBoardingIcon from "../assets/images/onboarding_icon.svg";
import requests from "../assets/images/Rrequests.svg";
import newReq from "../assets/images/New.svg";
import approve from "../assets/images/Approved.svg";
import completed from "../assets/images/completed.svg";
import reject from "../assets/images/Rejected.svg";
import consumer from "../assets/images/Consumer.svg";
import contractor from "../assets/images/Contractor.svg";
import feedbackIcon from "../assets/images/Feedback Active.svg";
import QueryIcon from "../assets/images/queries.svg";
import ServiceQueryIcon from "../assets/images/service.svg";
import contactUsIcon from "../assets/images/contactUs.svg";
import AskUsIcon from "../assets/images/askus.svg";

export const imageIcon = {
  code: codeIcon,
  payout: payoutIcon,
  dashboard: dashboardIcon,
  file: fileIcon,
  report: reportIcon,
  needHelp: needHelpIcon,
  setting: settingIcon,
  logout: logoutIcon,
  fstacLogo: fstacLogoIcon,
  home: homeIcon,
  sideRight: sideRightIcon,
  arrowDown: ArrowDownIcon,
  onBoarding: onBoardingIcon,
  requests: requests,
  newReq: newReq,
  approve: approve,
  reject: reject,
  consumer: consumer,
  contractor: contractor,
  completed: completed,
  feedback: feedbackIcon,
  query: QueryIcon,
  serviceQuery: ServiceQueryIcon,
  askus: AskUsIcon,
  contactus: contactUsIcon,
};

export const getIconPath = (iconName) => {
  return `../assets/images/${iconName}.svg`; // Return the icon path or an empty string if not found
};
