import {
  createBrowserRouter,
  createRoutesFromElements,
  BrowserRouter,
  Link,
  Route,
  Routes,
  RouterProvider,
} from "react-router-dom";

import { Login } from "../pages/login";
import { SignUp } from "../pages/signUp";
import { DashboardLayout } from "../pages/dashboardLayout";
import { Payout } from "../pages/payout";
import { ProtectedRoute } from "../routes/protectedRoutes";
import { PageNotFound } from "../pages/404Page";
import { Consumers } from "../pages/users/consumers";
import { UserReqests } from "../pages/requests/newRequests";
import { Dashboard } from "../pages/Dashboard/dashboard";
import { ProjectAdd } from "../pages/projects/addProject";
import { Contractors } from "../pages/users/contractors";
import { ApprovedReqests } from "../pages/requests/approvedRequests";
import { RejectedReqests } from "../pages/requests/rejectedRequests";
import { CompletedReqests } from "../pages/requests/completedRequests";
import { AddContractor } from "../pages/contractor/addContractor";
import { RequestedContractors } from "../pages/requests/requestedContractorList";
import { ProjectDetails } from "../pages/projects/projectDetails";
import { UserDetails } from "../pages/users/details";
import { Feedbacks } from "../pages/feedback/feedback";
import { AskUs } from "../pages/Queries/askus";
import { ContactUs } from "../pages/Queries/contactus";
import { ServiceQueries } from "../pages/Queries/serviceQueries";

const route = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="login" element={<Login />} />
      {/* <Route path="signup" element={<SignUp />} /> */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route index path="/" element={<Dashboard />} />
        <Route index path="/requests/new" element={<UserReqests />} />
        <Route index path="/requests/approved" element={<ApprovedReqests />} />
        <Route index path="/requests/rejected" element={<RejectedReqests />} />
        <Route
          index
          path="/requests/completed"
          element={<CompletedReqests />}
        />

        <Route index path="/consumers" element={<Consumers />} />
        <Route index path="/contractors" element={<Contractors />} />
        <Route index path="/contractors/add" element={<AddContractor />} />
        <Route index path="/requests/new/add/:id" element={<ProjectAdd />} />
        <Route
          index
          path="/requests/approved/contractor/:id"
          element={<RequestedContractors />}
        />
        <Route
          index
          path="/requests/approved/details/:id"
          element={<ProjectDetails />}
        />
        <Route
          index
          path="/requests/completed/details/:id"
          element={<ProjectDetails />}
        />
        <Route index path="/consumers/details/:id" element={<UserDetails />} />
        <Route
          index
          path="/contractors/details/:id"
          element={<UserDetails />}
        />
        <Route index path="/feedbacks" element={<Feedbacks />} />
        <Route index path="/queries/contactus" element={<ContactUs />} />
        <Route index path="/queries/askus" element={<AskUs />} />
        <Route
          index
          path="/queries/service/query"
          element={<ServiceQueries />}
        />
        {/* <Route path="/buyerReqest/list" element={<BuyerRequestList />} /> */}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export default route;
