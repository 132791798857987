import logo from "../assets/images/logo-small.svg";
import loginbanner from "../assets/images/loginbanner.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { login } from "../action/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export function Login() {
  const navigate = useNavigate();

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    identifier: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const loginForm = useFormik({
    initialValues: {
      identifier: "",
      password: "",
    },
    validationSchema, // Apply the validation schema
    onSubmit: async (values) => {
      const response = await login(values);
      if (response.status === 1) {
        navigate("/");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        toast.success("Login Successfully");
      } else {
        toast.error(response.message);
        navigate("/login");
      }
    },
  });

  return (
    <div className="login_page">
      <div className="container-full">
        <div className="login_content">
          <div className="login_left">
            <img src={loginbanner} />
          </div>
          <div className="login_right">
            <div className="login_right-content">
              <form onSubmit={loginForm.handleSubmit}>
                <div className="login_inner-content">
                  <div className="login_logo-box">
                    <img src={logo} alt="" className="login_logo" />
                  </div>
                  <div className="login">Welcome Back, Login!</div>

                  <div className="input-box">
                    <div className="input-label">
                      Email<span>*</span>
                    </div>
                    <div className="input_field wth-350">
                      <input
                        type="email"
                        className="input"
                        id="identifier"
                        name="identifier"
                        placeholder="Enter your registered email"
                        value={loginForm.values.identifier}
                        onChange={loginForm.handleChange}
                      />
                    </div>
                    {loginForm.touched.identifier &&
                      loginForm.errors.identifier && (
                        <div className="danger">
                          {loginForm.errors.identifier}
                        </div>
                      )}
                  </div>

                  <div className="input-box">
                    <div className="input-label">
                      Password<span>*</span>
                    </div>
                    <div className="input_field wth-350">
                      <input
                        type="password"
                        className="input"
                        placeholder="Enter your Password"
                        value={loginForm.values.password}
                        onChange={loginForm.handleChange}
                        id="password"
                      />
                    </div>
                    {loginForm.touched.password &&
                      loginForm.errors.password && (
                        <div className="danger">
                          {loginForm.errors.password}
                        </div>
                      )}
                  </div>

                  <div className="">
                    <button
                      className="submit-btn1"
                      disabled={
                        !loginForm.isValid || !loginForm.dirty // Disable if the form is not valid or not dirty (i.e., not touched)
                      }
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>

              <div className="login_right-footer">
                <div className="login_footer-left">@BuildNow GT 2024</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
