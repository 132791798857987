import React, { useState, useEffect } from "react";
import { useFormik, Field } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import * as Yup from "yup";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { handleKeyDown } from "../../helper/helper";
import { useNavigate, useParams } from "react-router-dom";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import { fetchService } from "../../action/service";
import {
  createContractor,
  createBusiness,
  uploadMediaImage,
} from "../../action/user";
import LocationSearchInput from "../../components/Location/searchPlace";
import backBtn from "../../assets/images/chevron-left.svg";

const validationSchema = Yup.object({
  userPayload: Yup.object({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string().required("Mobile is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  }),
  name: Yup.string().required("Business name is required"),
  visitingCharges: Yup.number()
    .required("Visiting charges are required")
    .min(0, "Visiting charges must be positive"),
  serviceId: Yup.array().min(1, "At least one service must be selected"),
  region: Yup.string().required("Region is required"),
  town: Yup.string().required("Town is required"),
  village: Yup.string().required("Village is required"),
  lat: Yup.string().required("Coordinates is required"),
});

export const AddContractor = () => {
  const id = useParams();
  const navigate = useNavigate();

  const [location, setLocation] = useState([]);
  const [services, setServices] = useState([]);
  const [isRegistered, setIsRegistered] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [password, setValidPassword] = useState(false);
  const [error, setError] = useState("");

  const addContractorFormik = useFormik({
    initialValues: {
      userPayload: {
        name: "",
        mobile: "",
        email: "",
        password: "",
        pic: "",
      },
      userId: "",
      name: "",
      isRegister: "",
      registrationNumber: "",
      registrationTIN: "",
      expereince: "",
      serviceId: [],
      completedproject: "",
      visitingCharges: "",
      location: "",
      address: "",
      teamDetails: {
        totalPersons: "",
        skilledWorkers: "",
        semiSkilledWorkers: "",
        porters: "",
        labors: "",
      },
      lat: "",
      long: "",
    },
    onSubmit: async (values, { resetForm }) => {
      values.lat = lat;
      values.long = long;
      values.isRegister = isRegistered;
      values.location = location;
      const payload = values.userPayload;
      console.log(values);
      const response = await createContractor(payload);
      if (response.status) {
        delete values.userPayload;
        values.userId = response?.data?.newUser?._id;
        if (response.status) {
          const response = await createBusiness(values);
          toast.success("Contractor created Successfully");
        }
        navigate("/contractors");
      } else {
        toast.error(response.message);
      }
    },
  });

  const isFormValid = Object.values(addContractorFormik.values).some(
    (value) => value !== "" && value !== null
  );

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadMediaImage(formData, "profile");
    if (res.status) {
      addContractorFormik.setFieldValue(`userPayload.pic`, res.data[0]);
    }
  };

  const getServices = async () => {
    const response = await fetchService();
    if (response.status) {
      setServices(
        response?.data?.services.map((item) => {
          return { value: item._id, label: item.name };
        })
      );
    }
  };

  const handleBlur = (identifier) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const isValid = regex.test(identifier);
    if (isValid) {
      setError("");
    } else {
      setError("Please enter a valid email address.");
    }
  };

  const handlePasswordValidation = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const isValid = regex.test(password);
    if (isValid) {
      setValidPassword("");
    } else {
      setValidPassword("Password policy does not match.");
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    const skilledWorkers =
      Number(addContractorFormik.values.teamDetails.skilledWorkers) || 0;
    const semiSkilled =
      Number(addContractorFormik.values.teamDetails.semiSkilledWorkers) || 0;
    const porters = Number(addContractorFormik.values.teamDetails.porters) || 0;
    const labors = Number(addContractorFormik.values.teamDetails.labors) || 0;

    const total = skilledWorkers + semiSkilled + porters + labors;

    addContractorFormik.setFieldValue(
      "teamDetails.totalPersons",
      Number(total)
    );
  }, [
    addContractorFormik.values.teamDetails.skilledWorkers,
    addContractorFormik.values.teamDetails.semiSkilledWorkers,
    addContractorFormik.values.teamDetails.porters,
    addContractorFormik.values.teamDetails.labors,
  ]);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl contractor_ttl">
              <img
                className="i_button"
                src={backBtn}
                alt="back"
                onClick={() => {
                  navigate("/contractors");
                }}
              />
              Create Contractor
            </div>
          </div>
        </div>
      </div>
      <form action="" onSubmit={addContractorFormik.handleSubmit}>
        <div className="section-heading">Contractor Details</div>

        <div className="payout_data-content mb-5">
          <div className="row mb-4">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Full Name
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter name"
                    id="beneficiary"
                    name="userPayload.name"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "userPayload.name",
                        e.target.value
                      );
                    }}
                    value={addContractorFormik.values.userPayload.name}
                    required
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">
                  Mobile
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter mobile"
                    id="beneficiary"
                    name="userPayload.mobile"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "userPayload.mobile",
                        e.target.value
                      );
                    }}
                    value={addContractorFormik.values.userPayload.mobile}
                    required
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="input-box">
                  <div className="input-label">
                    Email
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <input
                      type="email"
                      className="input"
                      placeholder="Enter email"
                      id="beneficiary"
                      name="userPayload.email"
                      onChange={(e) => {
                        addContractorFormik.setFieldValue(
                          "userPayload.email",
                          e.target.value
                        );
                      }}
                      value={addContractorFormik.values.userPayload.email}
                      required
                      onBlur={(e) => {
                        handleBlur(e.target.value);
                      }}
                    />
                  </div>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
                <div className="input-box">
                  <div className="input-label">
                    Password
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter password"
                      id="beneficiary"
                      name="userPayload.password"
                      onChange={(e) => {
                        addContractorFormik.setFieldValue(
                          "userPayload.password",
                          e.target.value
                        );
                      }}
                      value={addContractorFormik.values.userPayload.password}
                      required
                      onBlur={(e) => {
                        handlePasswordValidation(e.target.value);
                      }}
                    />
                  </div>
                  {password && <p style={{ color: "red" }}>{password}</p>}
                  <div className="mt-1 pass_policy">
                    <p>
                      <span>*</span> Password should contains atleast 1
                      Uppercase
                    </p>
                    <p>
                      <span>*</span> Password should contains atleast 1
                      Lowercase
                    </p>
                    <p>
                      <span>*</span> Password should contains atleast 1 Special
                      Character(@#$!$&)
                    </p>
                    <p>
                      <span>*</span> Password should contains minimum 8
                      characters
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* mobile */}
            <div className="col-lg-3">
              <div className="input-box">
                <div className="input-label">Image</div>
                <div className="option-card" style={{ display: "flex" }}>
                  <ThumbnailPicker
                    width="60%"
                    height="300px"
                    onFileSelect={(file) => {
                      const _URL = window.URL || window.webkitURL;

                      const img = new Image();
                      uploadImage(file);

                      img.src = _URL.createObjectURL(file);
                    }}
                    image={addContractorFormik.values.userPayload.pic}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-heading">Business Details</div>
        <div className="payout_data-content mb-5">
          <div className="row mb-4">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Business Name
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter business name"
                    id="beneficiary"
                    name="name"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue("name", e.target.value);
                    }}
                    value={addContractorFormik.values.name}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Experience</div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter work experience"
                    id="beneficiary"
                    name="expereince"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "expereince",
                        Number(e.target.value)
                      );
                    }}
                    value={addContractorFormik.values.expereince}
                    min={0}
                    max={50}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Visiting charges
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter visiting charge"
                    id="beneficiary"
                    name="visitingCharges"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "visitingCharges",
                        Number(e.target.value)
                      );
                    }}
                    value={addContractorFormik.values.visitingCharges}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Total Projects</div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter number of project completed"
                    id="beneficiary"
                    name="completedproject"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "completedproject",
                        Number(e.target.value)
                      );
                    }}
                    value={addContractorFormik.values.completedproject}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Services
                  <span>*</span>
                </div>

                <Select
                  isMulti
                  name="serviceId"
                  options={services}
                  onChange={(selectedOptions) => {
                    const serviceIds = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    addContractorFormik.setFieldValue("serviceId", serviceIds);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  required
                />
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Region
                  <span>*</span>
                </div>
                <Select
                  options={regions}
                  name="region"
                  onChange={(e) => {
                    addContractorFormik.setFieldValue("region", e.value);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  required
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Town
                  <span>*</span>
                </div>
                <Select
                  options={towns}
                  name="town"
                  onChange={(e) => {
                    addContractorFormik.setFieldValue("town", e.value);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  required
                />
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Coordinates
                  <span>*</span>
                </div>
                <LocationSearchInput
                  setLat={setLat}
                  setLong={setLong}
                  setAddress={setLocation}
                />
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Village
                  <span>*</span>
                </div>
                <Select
                  options={villages}
                  name="village"
                  onChange={(e) => {
                    addContractorFormik.setFieldValue("village", e.value);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  required
                />
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Address
                  <span>*</span>
                </div>
                <div className="input_field">
                  <textarea
                    type="text"
                    className="input"
                    placeholder="Enter address"
                    id="beneficiary"
                    name="address"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "address",
                        e.target.value
                      );
                    }}
                    value={addContractorFormik.values.address}
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label"></div>
                <div className="input-label">Is it Registered?</div>
                <div className="checkform_box">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="option1"
                      onChange={() => {
                        setIsRegistered(true);
                      }}
                    />
                    <label class="form-check-label" for="inlineRadio1">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="option2"
                      onChange={() => {
                        setIsRegistered(false);
                      }}
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {isRegistered ? (
                <div className="input-box">
                  <div className="input-label">
                    Register Number
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter register number"
                      id="beneficiary"
                      name="registrationNumber"
                      onChange={(e) => {
                        addContractorFormik.setFieldValue(
                          "registrationNumber",
                          e.target.value
                        );
                      }}
                      value={addContractorFormik.values.registrationNumber}
                      required
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-6">
              {isRegistered ? (
                <div className="input-box">
                  <div className="input-label">
                    TIN Number
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter TIN number"
                      id="beneficiary"
                      name="registrationTIN"
                      onChange={(e) => {
                        addContractorFormik.setFieldValue(
                          "registrationTIN",
                          e.target.value
                        );
                      }}
                      value={addContractorFormik.values.registrationTIN}
                      required
                    />
                  </div>
                </div>
              ) : (
                <div className="register_text">
                  Partners must be registered officially within 12 months of
                  using the platform.
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="section-heading">Team Details</div>
        <div className="payout_data-content">
          <div className="row">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Skilled Workers
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter skilled workers count"
                    id="beneficiary"
                    name="teamDetails.skilledWorkers"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "teamDetails.skilledWorkers",
                        Number(e.target.value)
                      );
                    }}
                    value={
                      addContractorFormik.values.teamDetails.skilledWorkers
                    }
                    min={0}
                    max={1000}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Semiskilled Workers
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter semiskilled workers count"
                    id="beneficiary"
                    name="teamDetails.semiSkilledWorkers"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "teamDetails.semiSkilledWorkers",
                        Number(e.target.value)
                      );
                    }}
                    value={
                      addContractorFormik.values.teamDetails.semiSkilledWorkers
                    }
                    min={0}
                    max={1000}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Porters
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter porters count"
                    id="beneficiary"
                    name="teamDetails.porters"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "teamDetails.porters",
                        Number(e.target.value)
                      );
                    }}
                    value={addContractorFormik.values.teamDetails.porters}
                    min={0}
                    max={1000}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Labors
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter labours count"
                    id="beneficiary"
                    name="teamDetails.labors"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "teamDetails.labors",
                        Number(e.target.value)
                      );
                    }}
                    value={addContractorFormik.values.teamDetails.labors}
                    min={0}
                    max={1000}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Total Workers
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter total workers"
                    id="beneficiary"
                    name="teamDetails.totalPersons"
                    onChange={(e) => {
                      addContractorFormik.setFieldValue(
                        "teamDetails.totalPersons",
                        Number(e.target.value)
                      );
                    }}
                    value={addContractorFormik.values.teamDetails.totalPersons}
                    min={0}
                    max={1000}
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="contact_submit-box">
            <button
              className="contact_btn cancel"
              type="button"
              onClick={() => {
                navigate("/contractors");
              }}
            >
              Cancel
            </button>
            <button className="contact_btn save" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
      {/* </div> */}
    </div>
  );
};
