import { usePagination } from "./paginationRange";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    changePageSize,
    showPageSizeView = true,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    if (currentPage === lastPage) {
      onPageChange(currentPage);
    } else {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage == 1) {
      onPageChange(currentPage);
    } else {
      onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange
    ? paginationRange[paginationRange.length - 1]
    : 20;

  return (
    <div>
      <div className="drBottom">
        {paginationRange.length > 1 ? (
          <div className="pagination">
            <div
              style={{ cursor: currentPage === 1 ? "no-drop" : "pointer" }}
              className="pagePrev"
              onClick={onPrevious}
            ></div>
            {paginationRange.map((pageNumber) => {
              return pageNumber !== "..." ? (
                <div
                  className={pageNumber === currentPage ? "active" : ""}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </div>
              ) : (
                <p className="dots">...</p>
              );
            })}
            <div
              style={{
                cursor: currentPage === lastPage ? "no-drop" : "pointer",
              }}
              onClick={onNext}
              className="pageNext"
            ></div>
          </div>
        ) : (
          <></>
        )}

        {showPageSizeView && (
          <div className="pageSelect">
            <span className="pageSelectTxt">view</span>
            <select
              className="form-select"
              value={pageSize}
              onChange={(e) => {
                changePageSize(Number(e.target.value));
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}> 25</option>
            </select>
            {/* <span className="pageSelectTxt">out of 25</span> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;

// export default PaginationView;
