import React from "react";
import Chart from "react-apexcharts";

const ProgressChart = ({ seriesData }) => {
  const options = {
    chart: {
      id: "progress-chart",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 4,
      colors: ["#000"],
      strokeColor: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="custom-tooltip">' +
          series[seriesIndex][dataPointIndex] +
          "</div>"
        );
      },
      style: {
        fontSize: "12px",
      },
      offsetY: -10,
      offsetX: 0,
    },
    colors: ["#000"],
  };

  const series = [
    {
      name: "New Applications",
      data: seriesData,
    },
  ];

  return (
    <div className="progress-chart">
      <Chart options={options} series={series} type="line" height="50" />
    </div>
  );
};

export default ProgressChart;
